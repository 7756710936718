/*---------------- Date time picker style to match with tailwind ---------------------*/
.react-datepicker-wrapper {
  /* @apply me-2; */
}

.react-datepicker__aria-live {
  @apply hidden;
}

.react-datepicker__input-container input {
  @apply block w-full rounded border border-gray-300 bg-white bg-calendar bg-no-repeat pl-8 text-base shadow-sm focus:border-gray-500 focus:ring-gray-500 disabled:bg-gray-100 md:text-sm;
  background-position: 0.5rem;
  background-size: 1.2rem;
}

.react-datepicker-popper {
  @apply z-40 w-72 rounded border-2 border-gray-200 bg-white px-3 py-2 text-sm shadow;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute left-auto right-0 top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute right-0 top-12 z-10 w-72 transform-none rounded border-2 border-gray-200 bg-white px-3 py-2 text-sm shadow;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex cursor-pointer flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 cursor-pointer text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex cursor-pointer justify-around;
}

.react-datepicker__quarter-wrapper {
  @apply flex cursor-pointer justify-around;
}

.react-datepicker__monthPicker,
.react-datepicker__quarterPicker {
  @apply mb-1 mt-2.5;
}

.react-datepicker__month-wrapper {
  @apply mb-2 mt-2 flex cursor-pointer flex-wrap justify-around;
}

.react-datepicker__year-wrapper {
  @apply mb-1 mt-2.5 flex cursor-pointer flex-wrap justify-around;
}

.react-datepicker__day-names {
  @apply flex cursor-pointer justify-around text-center text-xs font-medium text-gray-400;
}

.react-datepicker__day-name {
  @apply flex h-8 w-8 cursor-pointer items-center justify-center rounded-full py-1;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-10 top-1 flex h-6 w-6 items-center justify-center rounded !bg-chevron-left transition hover:bg-gray-200;
  text-indent: -600rem;
}

.react-datepicker__navigation--next {
  @apply right-2 top-1 flex h-6 w-6 items-center justify-center rounded !bg-chevron-right transition hover:bg-gray-200;
  text-indent: -600rem;
}

.react-datepicker__month-text {
  @apply flex h-8 w-16 cursor-pointer items-center justify-center rounded py-1 text-sm leading-loose text-gray-700 transition hover:bg-gray-200;
}

.react-datepicker__quarter-text {
  @apply flex h-8 w-14 cursor-pointer items-center justify-center rounded py-1 text-sm leading-loose text-gray-700 transition hover:bg-gray-200;
}

.react-datepicker__year-text {
  @apply mb-2 mt-2 flex h-8 w-14 cursor-pointer items-center justify-center rounded py-1 text-sm leading-loose text-gray-700 transition hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded py-1 text-sm leading-loose text-gray-700 transition hover:bg-gray-200;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--in-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--in-range,
.react-datepicker__year--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month--in-selecting-range,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__year--in-selecting-range {
  @apply bg-gray-200;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__month--selecting-range-start,
.react-datepicker__quarter--selecting-range-start,
.react-datepicker__year--selecting-range-start {
  @apply border-2 border-gray-500 bg-white;
}

.react-datepicker__day--selecting-range-end,
.react-datepicker__month--selecting-range-end,
.react-datepicker__quarter--selecting-range-end,
.react-datepicker__year--selecting-range-end {
  @apply border-2 border-gray-500 bg-white;
}

.react-datepicker__day--selected,
.react-datepicker__year-text--selected,
.react-datepicker__quarter--selected,
.react-datepicker__month--selected,
.react-datepicker__month--range-start,
.react-datepicker__month--range-end,
.react-datepicker__quarter--range-start,
.react-datepicker__quarter--range-end,
.react-datepicker__year--range-start,
.react-datepicker__year--range-end {
  @apply bg-gray-500 text-white hover:bg-gray-500;
}

.react-datepicker__day--range-start {
  @apply bg-gray-500 text-white hover:bg-gray-500 hover:text-white;
}

.react-datepicker__day--range-end {
  @apply bg-gray-500 text-white hover:bg-gray-500 hover:text-white;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled,
.react-datepicker__year-text--disabled {
  @apply text-gray-400;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  @apply h-52 overflow-y-auto;
}

.react-datepicker__header__dropdown {
  @apply flex justify-center space-x-2;
}

.react-datepicker__month-select {
  @apply rounded-md border border-gray-300 py-1 text-sm focus:border-gray-500 focus:shadow-sm focus:ring-black;
}

.react-datepicker__year-select {
  @apply rounded-md border border-gray-300 py-1 text-sm focus:border-gray-500 focus:shadow-sm focus:ring-black;
}

.react-datepicker__time-list-item {
  @apply cursor-pointer rounded-md p-2 text-sm font-medium text-slate-700 hover:bg-slate-300;
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
  @apply text-gray-400;
}

.react-datepicker__time-list-item--selected {
  @apply bg-slate-200;
}

.react-datepicker__header--time {
  @apply hidden;
}
